import React, {Component} from 'react';

class Countdown extends Component {

  constructor(props) {
    super(props);

    this.startDate = new Date(2021, 8-1, 12, 7, 40)
    this.endDate = new Date(2022, 1-1, 31)

    this.state = {
      currTime: new Date()
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(state => ({currTime: new Date()}))
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    // console.log(this.state.currTime)
    document.body.style = 'background: #5cdb95;';

    let millisLeft =  this.endDate.getTime() - this.state.currTime.getTime()

    const hasPassed = millisLeft < 0
    if (hasPassed) {
      millisLeft = -millisLeft
    }

    const day = 1000 * 60 * 60 * 24;
    
    const daysLeft = Math.floor(millisLeft/day)
    const hoursLeft = Math.floor((millisLeft - daysLeft*day)/day*24)
    const timeLeftDH = `${daysLeft} ${daysLeft === 1 ? 'dan' : 'dana'} i ${hoursLeft} sati`

    const millisElapsed = !hasPassed ? this.state.currTime.getTime() - this.startDate.getTime() : this.endDate.getTime() - this.startDate.getTime();
    const daysElapsed = Math.floor(millisElapsed/day)
    const hoursElapsed = Math.floor((millisElapsed - daysElapsed*day)/day*24)
    const timeElapsedDH = `${daysElapsed} ${daysElapsed === 1 ? 'dan' : 'dana'} i ${hoursElapsed} sati`

    return (
      <div className='screen-center'>
        <div className="clock-info">{!hasPassed ? 'Nikola se vraća za' : 'Nikola se vratio prije'}</div>
        <div className="clock">{timeLeftDH}</div>

        <div className='mt-5'/>

        <div className="clock-info">{!hasPassed ? 'Nikole nema već' : 'Nikole nije bilo'}</div>
        <div className="clock">{timeElapsedDH}</div>
      </div>
    );
  }
}

export default Countdown;
import React, {Suspense} from "react";
import {BrowserRouter, Route} from "react-router-dom";
import {Switch} from "react-router";
import LoadingPage from "./components/LoadingPage";

// convert to lazy import
// import PdfWizHome from "./components/pdf_wiz/PdfWizHome";
// import PdfWizBase from "./components/pdf_wiz/PdfWizBase";
// import PdfWizMerge from "./components/pdf_wiz/PdfWizMerge";
// import PdfWizSplit from "./components/pdf_wiz/PdfWizSplit";
import Countdown from "./components/countdown/Countdown";

const Home = React.lazy(() => import("./components/home/Home"));
const PageNotFound = React.lazy(() => import("./components/PageNotFound"));

const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
      <Suspense fallback={<LoadingPage/>}>
        <Switch>

          {/* Home */}
          <Route path="/" exact render={() => <Home/>}/>
          <Route path="/home" exact render={() => <Home/>}/>


          {/* Pdf Wiz */}
          {/*<Route path="/pdf-wiz/merge" render={() => <PdfWizMerge/>}/>*/}
          {/*<Route path="/pdf-wiz/split" render={() => <PdfWizSplit/>}/>*/}

          {/*<Route path="/pdf-wiz/pdf-to-word" render={() => <PdfWizSplit/>}/>*/}
          {/*<Route path="/pdf-wiz/word-to-pdf" render={() => <PdfWizSplit/>}/>*/}
          {/*<Route path="/pdf-wiz/remove-drm" render={() => <PdfWizSplit/>}/>*/}

          {/*<Route path="/pdf-wiz" exact render={() => <PdfWizHome/>}/>*/}

          {/*/!* todo remov dis*!/*/}
          {/*<Route path="/pdf-wiz/test" render={() => <PdfWizBase/>}/>*/}

          <Route path="/loading" render={() => <LoadingPage/>}/>

          <Route path="/countdown" exact render={() => <Countdown/>}/>

          {/* 404 page */}
          <Route render={() => <PageNotFound/>}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;

import React from 'react';
import Jumbotron from "react-bootstrap/Jumbotron";

const LoadingPage = () => {
  return (
    <Jumbotron
        fluid
        className="min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
    >
      <div className="spinner d-flex m-auto"/>

    </Jumbotron>
  );
};

export default LoadingPage;